@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'SuisseIntl';
        src: url('/assets/fonts/SuisseIntl-Regular-WebS.ttf');
    }

    @font-face {
        font-family: 'SuisseIntl';
        src: url('/assets/fonts/SuisseIntl-Regular-WebM.ttf');
    }

    @font-face {
        font-family: 'SuisseIntl';
        src: url('/assets/fonts/SuisseIntl-Regular-WebXL.ttf');
    }

    @font-face {
        font-family: 'SuisseIntl';
        src: url('/assets/fonts/SuisseIntl-Medium-WebM.woff2');
    }

    @font-face {
        font-family: 'SuisseIntl';
        src: url('/assets/fonts/SuisseIntl-Regular-WebM.woff');
    }

    @font-face {
        font-family: 'SuisseIntl';
        src: url('/assets/fonts/SuisseIntl-Regular-WebS.eot');
    }

    @font-face {
        font-family: 'SuisseIntl';
        src: url('/assets/fonts/SuisseIntl-Regular-WebS.woff');
    }

    @font-face {
        font-family: 'SuisseIntl';
        src: url('/assets/fonts/SuisseIntl-Regular-WebS.woff2');
    }

    @font-face {
        font-family: 'SuisseIntl';
        src: url('/assets/fonts/SuisseIntl-Regular-WebXL.woff');
    }

    @font-face {
        font-family: 'SuisseIntl';
        src: url('/assets/fonts/SuisseIntl-Regular-WebXL.woff2');
    }
    /* body */
    body {
        @apply bg-white dark:bg-black text-black dark:text-white;
        @apply antialiased;
        font-family: SuisseIntl;
        font-size: 14px;
    }

    select {
        @apply border-0 focus:ring-0;
    }
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

