@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

#email {
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  border-style: solid;
}

#email:focus {
  box-shadow: none;
}

/*overwrite text color in dark mode for login input*/
input.no-autofill-bkg:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: black !important;
}
